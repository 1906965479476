let store = new Vuex.Store ({
  state : {
    runningQueries: 0,
  },
  mutations: {
    incRunnnigQueries: (state) => { state.runningQueries ++ },
    decRunnnigQueries: (state) => { state.runningQueries -- }
  },
  actions: {
  }
})