let routes = [
  {   
    path: '/accueil',
    component: PAGES['accueil'], 
    name: 'pageAccueil' 
  },
  {   
    path: '/config',
    component: PAGES['config'], 
    name: 'pageConfig' 
  },
  { 
    path: '*',
    redirect: '/accueil'
  }
]

let router = new VueRouter({ 
  routes: routes,
  mode: 'history'
})


document.addEventListener("DOMContentLoaded", () => {
  moment.locale('fr')
  new Vue({ 
    router,
    store
  }).$mount('#app')
})
