var isMobile = function () {
  let stringToTest = (navigator.userAgent||navigator.vendor||window.opera)
  // eslint-disable-next-line no-useless-escape
  return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(stringToTest)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(stringToTest.substr(0,4))
}

Vue.mixin({
  data: () => {
    return {
      foo: null,
      server : {
        get : (uri, showLoading = true) => {
          return new Promise((resolve) => {
            var xhr = new XMLHttpRequest()
    
            xhr.onload = function () {
              if (xhr.status >= 200 && xhr.status < 300) {
                resolve(JSON.parse(xhr.response))
                if (showLoading) { store.commit('decRunnnigQueries') }
              } else {
                resolve(xhr.status)
              }
            }
    
            xhr.open('GET', uri)
            if (showLoading) { store.commit('incRunnnigQueries') }
            xhr.send()
          })
        },
        post : (uri, data, showLoading = true) => {
          return new Promise((resolve) => {
            var xhr = new XMLHttpRequest()
    
            xhr.onload = function () {
              if (xhr.status >= 200 && xhr.status < 300) {
                // console.log('xhr.response :', xhr.response);
                resolve(JSON.parse(xhr.response))
                if (showLoading) { store.commit('decRunnnigQueries') }
              } else {
                resolve(xhr.status)
              }
            }
    
            xhr.open('POST', uri)
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
            if (showLoading) { store.commit('incRunnnigQueries') }
            xhr.send(JSON.stringify(data))
          })
        }
      },
      isMobile: isMobile()
    }
  },
  computed: {
    now () {
      var currentdate = new Date(); 
      return  currentdate.getFullYear() + "-"  
              + ('0' + (currentdate.getMonth()+1)).slice(-2)  + "-" 
              + ('0' + currentdate.getDate()).slice(-2) + " "
              + ('0' + currentdate.getHours()).slice(-2) + ":"
              + ('0' + currentdate.getMinutes()).slice(-2) + ":"
              + ('0' + currentdate.getSeconds()).slice(-2)
    }
  },
  methods: {
    toUpper : (value) => {
      if (!value) return value
      return value.toUpperCase()
    },
    flUpper: (value) => {
      return value.charAt(0).toUpperCase() + value.substr(1)
    },
    formatDateComplete: (date, hours = false) => {
      // console.log('date :>> ', date);
      if (!date) return null
      const monthNames  = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
      const dayNames    = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];

      let d,m,y, jsDate, ymd, hms, h, mi

      ymd = date.split('-')
      y = ymd[0]
      m = ymd[1]
      d = ymd[2]
      jsDate = new Date(parseInt(y), parseInt(m) - 1, parseInt(d));

      if (hours) {
        hms = date.split(' ')[1].split(':')
        h = hms[0]
        mi = hms[1]
        // s = hms[2]
      }

      return dayNames[jsDate.getDay()] + ' ' + (parseInt(d) === 1 ? '1er' : parseInt(d)) + ' ' + monthNames[parseInt(m) - 1] + ' ' + y + (hours ? (' à ' + h + 'H' + mi) : '');
    },
    formatDate (string, withHours = false) {
      if (string) {
        let dateElements = string.substring(0,10).split('-')
        return dateElements[2] + '/' + dateElements[1] + '/' + dateElements[0].substr(2,2) + (withHours ? ' ' + string.substring(11, 16) : '')
      } else {0
        return null;
      }
    },
    noBs (string) { return string ? string.replace(/\s/g, '&nbsp;') : string },
    addBr (text) {
      return text ? text.replace(/(?:\r\n|\r|\n)+/g, '<br>') : text
    },
    mailValide (email) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).trim().toLowerCase());
    },
    telephoneValide (tel) {
      // eslint-disable-next-line no-useless-escape
      var re = /^0[1-9].{0,1}[0-9]{2}.{0,1}[0-9]{2}.{0,1}[0-9]{2}.{0,1}[0-9]{2}$/;
      return re.test(String(tel).trim().toLowerCase());
    },
  }
})